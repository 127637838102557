@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;
@use '../../styles/helpers/heading-gradient' as *;

.contact {
  @include container;
  padding-top: $component-spacing-mob;

  @include media(min-tablet) {
    padding-top: $component-spacing-des;
  }

  &__text,
  &__wrap {
    grid-column: 3 / 23;
  }

  &__text {
    @include heading-gradient(right);

    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-gap: 0;

    @include media(min-tablet) {
      grid-column: 5 / 21;
    }
  }

  &__column {
    position: relative;
    z-index: 2;
    grid-column: 1 / 19;

    @include media(max-mobile) {
      margin-bottom: vw(375, 32);
    }
  }

  &__column.is-address {
    @include media(min-tablet) {
      grid-column: 1 / 6;
    }
  }

  &__column.is-contact {
    @include media(min-tablet) {
      grid-column: 7 / 12;
    }
  }

  &__column.is-heading {
    @include media(max-mobile) {
      order: -2;
    }
    @include media(min-tablet) {
      text-align: right;
      grid-column: 13 / 19;
    }
  }

  &__heading {
    margin-bottom: vw(375, 12);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 20);
    }
  }

  &__wrap {
    background: $border-gradient;
    border-radius: $border-radius-mob-md;

    @include media(max-mobile) {
      margin-bottom: $heading-gap-mob;
    }

    @include media(min-tablet) {
      border-radius: $border-radius-des-md;
      aspect-ratio: 2.9;
      margin-top: vw(1440, 72);
      grid-column: 4 / 22;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;

    width: calc(100% - 4px);
    height: calc(100% - 4px);

    object-fit: cover;

    border-radius: calc($border-radius-mob-md - 1px) ;
    border: vw(375, 10) solid $color-black;

    @include media(min-tablet) {
      border: vw(1440, 10) solid $color-black;
      border-radius: calc($border-radius-des-md - 1px) ;
    }
  }
}
