@use '../foundation/vars' as *;
@use '../helpers/media' as *;
@use '../helpers/px-to-vw' as *;

html {
  ::-moz-selection {
    color: $color-white;
    background: $color-purple;
  }

  ::selection {
    color: $color-white;
    background: $color-purple;
  }
}

body {
  position: relative;
  font-family: $font-family-body;
  color: $color-white;
  background-color: $color-black;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
  color: $color-purple;
  text-decoration: none;
  will-change: background-color, color;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    color: $color-green;
  }
}

address {
  font-style: normal;
}

.main {
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // bottom: 0;
  overflow: hidden;
  z-index: 0;
  height: 100%;

  &::before {
    content: '';

    position: absolute;
    top: vw(375, -60);
    right: vw(375, 140);
    z-index: 0;

    width: vw(375, 235);
    height: vw(375, 1180);

    background: linear-gradient(180deg, rgba(1,220,154,0.15) 0%, rgba(126,47,255,0) 100%);
    transform: rotate(24deg);

    @include media(min-tablet) {
      top: vw(1440, -200);
      right: vw(1440, 580);

      width: vw(1440, 430);
      height: vw(1440, 3875);
    }
  }
}
