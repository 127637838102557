// breakpoints
$max-mobile: 767px;
$min-tablet: 768px;
$max-tablet: 1439px;
$min-desktop: 1440px;
$max-desktop: 1920px;

// media mixin
@mixin media($breakpoint) {
  @if $breakpoint == max-mobile {
    @media only screen and (max-width: $max-mobile) {
      @content;
    }
  }

  @if $breakpoint == min-mobile {
    @media only screen and (min-width: $min-mobile) {
      @content;
    }
  }

  @if $breakpoint == max-tablet {
    @media only screen and (max-width: $max-tablet) {
      @content;
    }
  }

  @if $breakpoint == min-tablet {
    @media only screen and (min-width: $min-tablet) {
      @content;
    }
  }

  @if $breakpoint == min-desktop {
    @media only screen and (min-width: $min-desktop) {
      @content;
    }
  }
}
