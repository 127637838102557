@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;
@use '../../styles/helpers/heading-gradient' as *;

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.vacancies {
  @include container;

  padding-top: $component-spacing-mob;

  @include media(min-tablet) {
    padding-top: $component-spacing-des;
  }

  &__heading,
  &__subheading,
  &__list {
    position: relative;
    z-index: 2;
  }

  &__text {
    @include heading-gradient;

    grid-column: 3 / 23;
    margin-bottom: $heading-gap-mob;

    @include media(min-tablet) {
      grid-column: 3 / 14;
      margin-bottom: $heading-gap-des;
    }
  }

  &.has-1 &__list {
    grid-column: 6 / 20;
    grid-template-columns: repeat(1, 1fr);
  }

  &.has-2 &__list{
    grid-column: 3 / 23;
    grid-template-columns: repeat(2, 1fr);
  }

  &__list {
    grid-column: 2 / 24;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: vw(375, 24);

    @include media(min-tablet) {
      grid-gap: vw(1440, 32);
    }
  }

  &__item {
    background: linear-gradient(-45deg, $color-purple, darken($color-green, 7), $color-purple, darken($color-green, 7));
    background-size: 800% 800%;
    animation: gradient 15s ease infinite;
    border-radius: $border-radius-mob-md;

    @include media(max-mobile) {
      padding: 2px;
    }

    @include media(min-tablet) {
      position: relative;
      height: vw(1440, 285);
      border-radius: $border-radius-des-md;
    }
  }

  &__link {
    transform: translateY(0);
    transition: all 1.5s $ease-out-expo;
    border-radius: $border-radius-des-md;

    @include media(max-mobile) {
      grid-column: 1 / 4;
      border-radius: $border-radius-mob-md;
    }

    &:hover {
      box-shadow: 0 0 vw(375, 14) $color-purple;
      transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);

      @include media(min-tablet) {
        box-shadow: 0 0 vw(1440, 14) $color-purple;
        transform: translateY(vw(1440, -20));
      }
    }
  }

  &__inner {
    padding: vw(375, 32);

    border-radius: calc($border-radius-mob-md - 1px) ;
    border: vw(375, 10) solid $color-black;
    padding: vw(375, 24);

    @include media(min-tablet) {
      position: absolute;
      z-index: 1;
      top: 2px;
      left: 2px;
      height: calc(100% - 4px);
      width: calc(100% - 4px);

      border: vw(1440, 10) solid $color-black;
      border-radius: calc($border-radius-des-md - 1px) ;
      display: flex;
      flex-direction: column;
      padding: vw(1440, 24);

      .heading {
        margin-top: auto;
        margin-bottom: vw(1440, 16);
      }
    }
  }

  &__details {
    font-size: vw(375, 16);

    color: $color-white;
    opacity: .5;

    @include media(min-tablet) {
      font-size: vw(1440, 20);
    }
  }

}
