@use '../foundation/vars' as *;
@use '../helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;

.richtext {
  font-size: vw(375, 16);
  line-height: vw(375, 30);

  @include media(min-tablet) {
    font-size: vw(1440, 20);
    line-height: vw(1440, 34);
  }

  &.size-lg {
    font-size: vw(375, 18);
    line-height: vw(375, 33);

    @include media(min-tablet) {
      font-size: vw(1440, 22);
      line-height: vw(1440, 38);
    }
  }

  p, ul, ol {
   color: $color-grey;
  }

  strong {
    color: $color-white;
  }

  ul {
    padding-left: 0;
    list-style: none;

    @include media(min-tablet) {
      padding-left: vw(1440, 40);
    }

    li {
      position: relative;
      padding-left: vw(375, 35);

      @include media(min-tablet) {
        padding-left: vw(1440, 35);
      }

      &::before {
        content: '';
        top: vw(375, 12);
        left: 0;
        position: absolute;
        height: vw(375, 2);
        width: vw(375, 16);
        // background: linear-gradient(45deg, $color-purple 0%, $color-green 80%);
        background: $color-green;
        border-radius: vw(375, 2);

        @include media(min-tablet) {
          top: vw(1440, 16);
          height: vw(1440, 2);
          width: vw(1440, 16);
          border-radius: vw(1440, 2);
        }
      }
    }
  }

  ol {
    padding-left: vw(375, 65);
    list-style: decimal;

    @include media(min-tablet) {
      padding-left: vw(1440, 65);
    }

    li {
      padding-left: vw(375, 10);

      @include media(min-tablet) {
        padding-left: vw(1440, 10);
      }
    }
  }

  ul,
  ol {
    li + li {
      margin-top: vw(375, 8);

      @include media(min-tablet) {
        margin-top: vw(1440, 8);
      }
    }
  }

  h3,
  h4 {
    font-family: $font-family-body;
    font-weight: $font-weight-bold;
    margin-top: vw(375, 50);

    @include media(min-tablet) {
      margin-top: vw(1440, 50);
    }
  }

  h3 {
    font-size: vw(375, 24);
    line-height: vw(375, 29);

    @include media(min-tablet) {
      font-size: vw(1440, 28);
      line-height: vw(1440, 34);
    }
  }

  h4 {
    font-size: vw(375, 20);
    line-height: vw(375, 24);

    @include media(min-tablet) {
      font-size: vw(1440, 24);
      line-height: vw(1440, 29);
    }
  }

  h3 + p,
  h4 + p,
  h3 + ul,
  h4 + ul {
    margin-top: vw(375, 32);

    @include media(min-tablet) {
      margin-top: vw(1440, 32);
    }
  }

  p + p {
    margin-top: vw(375, 32);

    @include media(min-tablet) {
      margin-top: vw(1440, 32);
    }
  }

  p + ul,
  p + ol {
    margin-top: vw(375, 32);

    @include media(min-tablet) {
      margin-top: vw(1440, 32);
    }
  }

  ul + p,
  ol + p {
    margin-top: vw(375, 32);

    @include media(min-tablet) {
      margin-top: vw(1440, 32);
    }
  }
}
