@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;
@use '../../styles/helpers/heading-gradient' as *;

.cta {
  @include container;
  padding-top: $component-spacing-mob;

  @include media(min-tablet) {
    padding-top: $component-spacing-des;
  }

  &__block {
    text-align: center;
    position: relative;
    grid-column: 2 / 24;

    background: $border-gradient;

    border-radius: $border-radius-mob-md;

    @include media(min-tablet) {
      grid-column: 5 / 21;
      border-radius: $border-radius-des-md;
    }
  }

  &__inner {
    @include heading-gradient(center);

    position: relative;
    display: flex;
    flex-direction: column;
    gap: vw(375, 16);

    padding: vw(375, 38) vw(375, 16);
    margin: 2px;
    background: darken($color-purple, 47);

    border-radius: $border-radius-mob-md;

    @include media(min-tablet) {
      padding: vw(1440, 42);
      border-radius: $border-radius-des-md;
      gap: vw(1440, 16);
    }

    * {
      position: relative;
      z-index: 1;
    }
  }

  @include media(max-mobile) {
    &__buttons {
      display: flex;
      flex-direction: column;
      gap: vw(375, 16);
      margin-top: vw(375, 16);
    }
  }

  &__sep {
    font-size: vw(375, 18);
    line-height: vw(375, 33);
    padding: 0 10px;

    @include media(min-tablet) {
      font-size: vw(1440, 22);
      line-height: vw(1440, 38);
    }
  }
}
