@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/container' as *;

.error-404 {
  &__container {
    @include container;

    padding-top: vw(375, 170);
    margin-top: 0;
    text-align: center;

    @include media(min-tablet) {
      padding-top: vw(1440, 235);
    }
  }

  &__heading,
  &__button {
    grid-column: 3 / 23;

    @include media(min-tablet) {
      grid-column: 6 / 20;
    }
  }

  &__heading {
    margin-bottom: vw(360, 40);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 40);
    }
  }
}
