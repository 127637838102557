@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/hide-scrollbar' as *;

.nav {
  &__item {
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    color: $color-white;

    &:hover {
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
      will-change: background-color, color;
      color: $color-green;
    }
  }
}

// Mobile styles
@include media(max-mobile) {
  .nav {
    @include hide-scrollbar;

    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba($color-black, .75);
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.83, 0, 0.17, 1);
    z-index: 10;

    backdrop-filter: blur(0);
    -webkit-backdrop-filter: blur(0);

    // &::before {
    //   content: '';
    //   position: absolute;
    //   backdrop-filter: blur(0);
    //   -webkit-backdrop-filter: blur(0);
    //   left: 0;
    //   top: 0;
    //   bottom: 0;
    //   right: 0;
    //   transition: all 0.35s cubic-bezier(0.83, 0, 0.17, 1);
    // }

    &__item {
      width: 100%;
      transform: translateY(vw(350, 100));
      padding: vw(375, 16) 0;
      font-size: vw(375, 32);
      line-height: vw(375, 32);
      transition: all 0.15s cubic-bezier(0.83, 0, 0.17, 1);
      opacity: 0;
      text-align: center;
      font-weight: $font-weight-medium;
    }
  }

  .nav-open {
    .nav {
      transition: all 0.35s cubic-bezier(0.83, 0, 0.17, 1);
      visibility: visible;
      opacity: 1;

      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);

      // &::before {
      //   transition: all 0.35s cubic-bezier(0.83, 0, 0.17, 1);
      //   backdrop-filter: blur(10px);
      //   -webkit-backdrop-filter: blur(10px);
      // }

      @for $i from 0 through 15 {
        &__item:nth-child(#{$i + 1}) {
          transition-delay: 0.05s * $i + 0.15;
        }
      }

      &__item {
        opacity: 1;
        transform: translateY(0);
        transition: all 1.25s $ease-out-expo;
      }
    }

    &.app {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
  }
}

// Desktop styles
@include media(min-tablet) {
  .nav {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 1;

    &__item {
      display: flex;
      align-items: center;
      position: relative;

      margin: 0 vw(1440, 16);

      font-size: vw(1440, 18);
      line-height: vw(1440, 18);
      padding: vw(1440, 15) 0;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
