@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;
@use '../../styles/helpers/heading-gradient' as *;

.hero {
  @include container;
  padding-top: vw(375, 145);

  @include media(min-tablet) {
    padding-top: vw(1440, 250);
  }

  &__content {
    grid-column: 3 / 23;

    @include media(min-tablet) {
      grid-column: 3 / 17;
    }

    &.has-padding {
      padding-top: vw(375, 28);

      @include media(min-tablet) {
        padding-top: vw(1440, 28);
      }
    }
  }

  &__heading-container {
    @include heading-gradient;
  }

  .subheading,
  &__heading,
  &__details  {
    position: relative;
    z-index: 3;
  }

  &__heading {
    span {
      background: -webkit-linear-gradient(0.5turn, $color-green, $color-purple);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__details {
    margin-top: vw(375, 24);

    @include media(min-tablet) {
      margin-top: vw(1440, 32);

      &.is-indent {
        padding-left: $col-1;
      }
    }
  }

  &__button {
    position: relative;
    z-index: 1;

    &.has-margin {
      margin-top: vw(375, 24);

      @include media(min-tablet) {
        margin-top: vw(1440, 32);
      }
    }

    &.is-sticky {
      @include media(max-mobile) {
        position: fixed;
        bottom: $col-1;
        left: $col-1;
        right: $col-1;
        margin-top: 0;
        z-index: 3;
        transition: bottom 0.75s $ease-out-expo;
      }
    }

    &:not(.is-visible) {
      @include media(max-mobile) {
        bottom: calc($col-4 * -1);
        transition: bottom 0.75s $ease-out-expo;
      }
    }
  }
}
