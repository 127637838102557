@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;
@use '../../styles/helpers/heading-gradient' as *;

.text {
  @include container;

  padding-top: $component-spacing-mob;

  @include media(min-tablet) {
    padding-top: $component-spacing-des;
  }

  &__heading,
  &__body {
    grid-column: 3 / 23;

    * {
      position: relative;
      z-index: 2;
    }
  }

  &__heading {
    @include media(max-mobile) {
      margin-bottom: vw(375, 24);
    }

    @include media(min-tablet) {
      grid-column: 3 / 9;
    }
  }

  &__body {
    @include media(min-tablet) {
      grid-column: 10 / 23;

      *:first-child {
        margin-top: 0;
      }
    }
  }

  + .text {
    padding-top: vw(375, 60);

    @include media(min-tablet) {
      padding-top: vw(1440, 120);
    }
  }

  @include media(min-tablet) {
    &:nth-child(odd) &__heading {
      @include heading-gradient;
    }

    &:nth-child(even) &__body {
      @include heading-gradient(right);
    }
  }
}
