@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;
@use '../../styles/helpers/heading-gradient' as *;

:root {
  --size: #{vw(375, 200)};
  --gap:  #{vw(375, 16)};
  --duration: 140s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));

  @include media(min-tablet) {
    --size: #{vw(1440, 200)};
    --gap:  #{vw(1440, 24)};
    --scroll-end: calc(-100% - var(--gap));
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

.logo-wall {
  @include container;

  padding-top: $component-spacing-mob;


  @include media(min-tablet) {
    padding-top: $component-spacing-des;
  }

  &__text {
    @include heading-gradient(right);

    grid-column: 3 / 23;
    margin-bottom: $heading-gap-mob;

    @include media(min-tablet) {
      grid-column: 7 / 19;
      margin-bottom: $heading-gap-des;
    }
  }

  &__subheading,
  &__heading {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  &__wrapper {
    grid-column: 1 / 25;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin: auto;
    max-width: 100vw;
  }

  &__row {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
    mask-image: linear-gradient(
      var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );
  }

  &__row.is-reverse &__items {
    animation-direction: reverse;
    animation-delay: -3s;
  }

  &__items {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
  }

  &__item {
    display: grid;
    place-items: center;
    width: vw(375, 172);
    height: vw(375, 104);
    padding: calc(var(--size) / 10);
    border-radius: $border-radius-mob-sm;
    border: 2px solid darken($color-purple, 10);
    background: darken($color-purple, 47);

    @include media(min-tablet) {
      border-radius: $border-radius-des-sm;
      width: vw(1440, 200);
      height: vw(1440, 120);
    }
  }

  &__image {
    max-width: vw(375, 105);
    max-height: vw(375, 52);
    mix-blend-mode: screen;
    opacity: .75;
    transition: opacity 0.5s $ease-out-expo;
    will-change: opacity;

    @include media(min-tablet) {
      max-width: vw(1440, 115);
      max-height: vw(1440, 60);
    }
  }

  // &__item:hover &__image {
  //   opacity: 1;
  //   transition: opacity 0.5s $ease-out-expo;
  // }
}
