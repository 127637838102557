@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;

.footer {
  display: flex;

  margin-top: $component-spacing-mob;
  margin-left: $col-1;
  margin-right: $col-1;
  padding: vw(375, 24) 0;

  border-top: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, $color-green, $color-purple);

  font-size: vw(375, 12);
  line-height: vw(375, 15);
  color: $color-grey;

  @include media(max-mobile) {
    flex-direction: column;
  }

  @include media(min-tablet) {
    justify-content: space-between;
    align-items: center;

    margin-top: $component-spacing-des;
    padding:  vw(1440, 24) 0;

    font-size: vw(1440, 14);
    line-height: vw(1440, 17);
  }

  &__copy,
  &__about {
    @include media(min-tablet) {
      display: flex;
      align-items: center;
    }
  }

  &__copy {
    @include media(max-mobile) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: vw(375, 16);
    }

    @include media(min-tablet) {
      padding-left: vw(1440, 8);
    }
  }

  &__copy,
  &__logo {
    @include media(max-mobile) {
      margin-bottom: vw(375, 16);
    }
  }

  &__about {
    @include media(max-mobile) {
      padding-left: vw(375, 16);
    }

    @include media(min-tablet) {
      padding-right: vw(1440, 8);
    }
  }

  &__logo {
    height: vw(375, 30);

    @include media(min-tablet) {
      height: vw(1440, 30);
      margin-right: vw(1440, 16);
    }
  }
}
