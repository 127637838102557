
@mixin container($align: 'start') {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-gap: 0;

  @if $align == 'start' {
    align-items: start;
  }

  @if $align == 'center' {
    align-items: center;
  }

  position: relative;
}
