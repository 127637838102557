@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: vw(375, 20) $col-2 vw(375, 60) $col-2;
  will-change: top;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 2, 15, 1) 25%, rgba(0, 2, 15, 0) 100%);
    z-index: 0;
  }


  @include media(min-tablet) {
    padding: vw(1440, 20) $col-1 vw(1440, 60) $col-1;
  }

  &__logo-img {
    position: relative;
    z-index: 1;
    height: vw(375, 40);

    @include media(min-tablet) {
      height: vw(1440, 48);
    }
  }
}

@include media(max-mobile) {
  .headroom--pinned {
    transition: top 0.75s $ease-out-expo;
    &::after {
      transition: opacity 1.5s $ease-out-expo;
      opacity: 1;
    }
  }
  .headroom--unpinned {
    top: vw(375, -120);
    transition: top 0.75s $ease-out-expo;

    &::after {
      transition: opacity 0.75s $ease-out-expo;
      opacity: 0;
    }
  }
}
