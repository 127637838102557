@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;

.hamburger {
  display: flex;
  z-index: 1;
  position: relative;
  width: vw(360, 30);
  height: vw(360, 24);
  z-index: 99;

  @include media(min-tablet) {
    display: none;
  }

  span {
    position: absolute;
    left: vw(360, 0);
    width: $col-2;
    height: vw(360, 3);
    background: $color-white;
    opacity: 2;
    border-radius: vw(360, 1.5);
    transform: rotate(0deg);
    transition: all 0.3s cubic-bezier(0.87, 0, 0.13, 1);

    &:nth-child(1) {
      top: vw(360, 0);
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: calc(50% - vw(360, 1.5));
    }

    &:nth-child(4) {
      bottom: vw(360, 0);
    }
  }
}

.nav-open {
  .hamburger {
    span {
      &:nth-child(1),
      &:nth-child(4) {
        left: 50%;
        width: 0%;
        opacity: 0;
      }

      &:nth-child(1) {
        top: calc(50% - vw(360, 1.5));
      }

      &:nth-child(4) {
        bottom: calc(50% - vw(360, 1.5));
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}
