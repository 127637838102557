@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;

.button {
  display: inline-flex;
  align-content: center;
  justify-content: center;

  font-family: $font-family-heading;
  font-weight: $font-weight-medium;
  font-size: vw(375, 20);
  line-height: vw(375, 20);
  text-decoration: none;
  color: $color-white;
  white-space: nowrap;

  min-width: vw(375, 180);
  padding: vw(375, 14) vw(375, 24);
  border-radius: $border-radius-mob-sm;

  transition: box-shadow 1.5s $ease-out-expo;
  will-change: box-shadow;
  box-shadow: 0 0 0 $color-purple;

  @include media(min-tablet) {
    font-size: vw(1440, 20);
    line-height: vw(1440, 20);

    min-width: vw(1440, 180);
    padding: vw(1440, 14) vw(1440, 24);
    border-radius: $border-radius-des-sm;
  }

  &:hover {
    color: $color-white;
    transition: box-shadow 0.25s $ease-out-expo;
  }

  &.color-primary {
    background: linear-gradient(180deg, rgba(126,47,255,1) 0%, rgba(87,29,182,1) 100%);

    &:hover {
      box-shadow: 0 0 vw(375, 14) $color-purple;

      @include media(min-tablet) {
        box-shadow: 0 0 vw(1440, 14) $color-purple;
      }
    }
  }

  &.color-secondary {
    background: linear-gradient(180deg, rgb(0, 187, 131) 0%, rgb(2, 130, 92) 100%);

    &:hover {
      box-shadow: 0 0 vw(375, 14) $color-green;

      @include media(min-tablet) {
        box-shadow: 0 0 vw(1440, 14) $color-green;
      }
    }
  }
}
