@forward '../../../node_modules/tiny-slider/src/tiny-slider.scss';

@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;

.tns-outer {
  margin-top: vw(375, 52);

  @include media(min-tablet) {
    margin-top: vw(1440, 60);
  }
}

.carousel {
  padding: vw(375, 0) vw(375, 16);

  @include media(min-tablet) {
    padding: vw(1440, 0) vw(1440, 16);
  }

  &:not(.tns-slider) {
    display: flex;

    @include media(max-mobile) {
      height: vw(375, 302);
    }

    @include media(min-tablet) {
      justify-content: flex-end;
      height: vw(1440, 508);
      padding-top: vw(1440, 150);

      .carousel__item {
        width: vw(1440, 360);
      }
    }
  }

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  &__inner {
    overflow: hidden;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    z-index: 1;
    background: linear-gradient(320deg, rgba(15,17,43,1) 0%, rgba(36,37,49,1) 100%);     border-radius: vw(1440, 20);
    border-radius: calc($border-radius-mob-md - 1px);

    @include media(min-tablet) {
      border-radius: calc($border-radius-des-md - 1px);
    }
  }

  &__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  &__item {
    height: $col-16;
    position: relative;
    border-radius: $border-radius-mob-md;
    background: $border-gradient;
    margin: 0 vw(375, 12);
    overflow: hidden;

    @include media(min-tablet) {
      height: $col-6;
      padding: 0 vw(1440, 16);
      margin: 0 vw(1440, 16);
      border-radius: $border-radius-des-md;
    }

    &.has-link  {
      &::after {
        will-change: opacity;
        transition: opacity 0.35s ease-in-out;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        content: '';
        background: $border-gradient-hover;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
        .carousel__image {
          transition: transform 0.35s $ease-out-back;
          transform: translateX(-50%) scale(1.05);
        }
      }
    }
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    padding: vw(375, 80) vw(375, 32) vw(375, 24);
    background: linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%);

    @include media(min-tablet) {
      padding: vw(1440, 80) vw(1440, 32) vw(1440, 24);
    }
  }

  &__image {
    position: absolute;
    left: 50%;
    top: vw(375, 16);
    transform: translateX(-50%) scale(1);
    transition: transform 0.5s $ease-out-back;

    max-width: vw(375, 210);
    max-height: vw(375, 160);
    z-index: 2;

    @include media(min-tablet) {
      top: vw(1440, 32);
      max-width: vw(1440, 340);
      max-height: vw(1440, 250);
    }
  }
}
.tns-controls {
  display: flex;
  justify-content: flex-end;
  padding: 0 $col-1 vw(1440, 40) 0;
  gap: vw(1440, 20);

  button {
    position: relative;
    width: vw(1440, 48);
    height: vw(1440, 48);
    background: $border-gradient;
    border-radius: vw(1440, 24);
    will-change: opacity;
    transition: opacity 0.25s ease-in-out;

    &:disabled {
      opacity: .25;
      transition: opacity 0.5s $ease-out-expo;
    }

    &:not(:disabled):hover {
      &:first-child::after {
        transform: translate(-60%, -50%);
      }
      &:last-child::after {
        transform: rotate(180deg) translate(40%, 50%);
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: calc(vw(1440, 48) - 4px);
      height: calc(vw(1440, 48) - 4px);
      top: 2px;
      left: 2px;
      background-color: #001e21;
      z-index: 1;
      border-radius: vw(1440, 23.25) ;
    }

    &:first-child::after,
    &:last-child::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      width: vw(1440, 24);
      height: vw(1440, 24);
      transition: transform 0.5s $ease-out-expo;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9InByZWZpeF9fcHJlZml4X19mZWF0aGVyIHByZWZpeF9fcHJlZml4X19mZWF0aGVyLWFycm93LWxlZnQiPjxwYXRoIGQ9Ik0xOSAxMkg1bTcgN2wtNy03IDctNyIvPjwvc3ZnPg==');
    }
    &:first-child::after {
      transform: translate(-50%, -50%);
    }
    &:last-child::after {
      transform: rotate(180deg) translate(50%, 50%);
    }
  }
}
