@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;

@mixin heading-gradient($position: 'left') {
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    z-index: 0;
    content: '';
    width: vw(375, 500);
    height: vw(375, 500);
    border-radius: vw(375, 250);
    background: radial-gradient(circle, rgba(126,47,255,0.1) 0%, rgba(126,47,255,0) 70%);

    @include media(min-tablet) {
      width: vw(1440, 900);
      height: vw(1440, 900);
      border-radius: vw(1440, 450);
    }

    @if $position == 'left' {
      top: vw(375, -250);
      left:vw(375, -250);

      @include media(min-tablet) {
        top: vw(1440, -450);
        left:vw(1440, -450);
      }
    }

    @if $position == 'right' {
      top: vw(375, -250);
      right: vw(375, -250);

      @include media(min-tablet) {
        top: vw(1440, -450);
        right: vw(1440, -450);
      }
    }

    @if $position == 'center' {
      left: 20%;
      bottom: vw(375, -250);

      @include media(min-tablet) {
        left: 50%;
        bottom: vw(1440, -350);
      }
    }
  }
}
