@use '../foundation/vars' as *;
@use '../helpers/px-to-vw' as *;
@use '../helpers/media' as *;
@use '../helpers/letter-spacing' as *;

.heading {
  color: $color-white;

  &.size-xl,
  &.size-lg,
  &.size-md  {
    font-family: $font-family-heading;
  }

  &.size-xl {
    font-weight: $font-weight-semi-bold;

    font-size: vw(375, 48);
    line-height: vw(375, 50);

    @include media(min-tablet) {
      font-size: vw(1440, 70);
      line-height: vw(1440, 74);
    }
  }

  &.size-lg,
  &.size-md  {
    font-weight: $font-weight-medium;
  }

  &.size-lg {
    font-size: vw(375, 42);
    line-height: vw(375, 44);

    @include media(min-tablet) {
      font-size: vw(1440, 52);
      line-height: vw(1440, 54);
    }
  }

  &.size-md {
    font-size: vw(375, 32);
    line-height: vw(375, 36);

    @include media(min-tablet) {
      font-size: vw(1440, 42);
      line-height: vw(1440, 44);
    }
  }

  &.size-sm,
  &.size-xs {
    font-family: $font-family-body;
    font-weight: $font-weight-bold;
  }

  &.size-sm {
    font-size: vw(375, 24);
    line-height: vw(375, 29);

    @include media(min-tablet) {
      font-size: vw(1440, 28);
      line-height: vw(1440, 34);
    }
  }

  &.size-xs {
    font-size: vw(375, 20);
    line-height: vw(375, 24);

    @include media(min-tablet) {
      font-size: vw(1440, 24);
      line-height: vw(1440, 29);
    }
  }
}

.subheading {
  display: block;
  font-family: $font-family-heading;
  font-weight: $font-weight-bold;
  letter-spacing: spacing(100);
  color: $color-green;
  text-transform: uppercase;
  font-size: vw(375, 13);
  line-height: vw(375, 16);

  margin-bottom: vw(375, 8);

  @include media(min-tablet) {
    font-size: vw(1440, 16);
    line-height: vw(1440, 20);
    margin-bottom: vw(1440, 8);
  }
}
