/*  ==========================================================================
    Fonts (@font-face)
    for google fonts use:
    https://gwfh.mranftl.com/fonts
    ========================================================================== */

/* bai-jamjuree-500 - latin */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 500;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../src/assets/fonts/bai-jamjuree-v11-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* bai-jamjuree-600 - latin */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 600;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../src/assets/fonts/bai-jamjuree-v11-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* bai-jamjuree-700 - latin */
@font-face {
  font-family: 'Bai Jamjuree';
  font-style: normal;
  font-weight: 700;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../src/assets/fonts/bai-jamjuree-v11-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../src/assets/fonts/inter-v13-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  src: url('../src/assets/fonts/inter-v13-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
