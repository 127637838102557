@use '../helpers/media' as *;
@use '../helpers/px-to-vw' as *;

// Colors
$color-purple: #7e2fff;
$color-green: #01dc9a;
$color-black: #00020f;
$color-grey: hsl(228, 3%, 71%);
$color-white: #fff;

// Gradients
$border-gradient: linear-gradient(128deg, $color-purple 0%, $color-green 100%);
$border-gradient-hover: linear-gradient(0deg, $color-purple 0%, $color-green 100%);

// Theming
$color-selection-color: $color-white;
$color-selection-background: $color-purple;

// Fonts
$font-family-body: 'Inter', helvetica, arial, sans-serif;
$font-family-heading: 'Bai Jamjuree', helvetica, arial, sans-serif;

// Type
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 200;

// Columns
$col-1: calc(100vw / 24 * 1);
$col-2: calc(100vw / 24 * 2);
$col-4: calc(100vw / 24 * 4);
$col-6: calc(100vw / 24 * 6);
$col-16: calc(100vw / 24 * 16);

// Easings
$ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
$ease-in-back: cubic-bezier(0.36, 0, 0.66, -0.56);
$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
$ease-in-expo: cubic-bezier(0.7, 0, 0.84, 0);
$ease-in-quart: cubic-bezier(0.5, 0, 0.75, 0);

// Border radius
$border-radius-mob-md: vw(375, 20);
$border-radius-mob-sm: vw(375, 10);
$border-radius-des-md: vw(1440, 20);
$border-radius-des-sm: vw(1440, 10);

// Spacing
$component-spacing-mob: vw(375, 120);
$component-spacing-des: vw(1440, 180);
$heading-gap-mob: vw(375, 32);
$heading-gap-des: vw(1440, 72);

@media (prefers-color-scheme: dark) {
  :root {

  }
}
