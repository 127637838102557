@use '../../styles/foundation/vars' as *;
@use '../../styles/helpers/media' as *;
@use '../../styles/helpers/px-to-vw' as *;
@use '../../styles/helpers/container' as *;
@use '../../styles/helpers/heading-gradient' as *;

.text-image {
  @include container(center);

  // margin: $component-spacing-mob 0;
  padding-top: $component-spacing-mob;

  @include media(min-tablet) {
    // margin: $component-spacing-des 0;
    padding-top: $component-spacing-des;
  }

  &__text,
  &__wrap {
    position: relative;
    z-index: 10;
    grid-column: 3 / 23;

    @include media(min-tablet) {
      grid-row: 1;
    }
  }

  &.is-text-image &__text {
    @include heading-gradient(left);

    @include media(min-tablet) {
      grid-column: 3 / 12;
    }
  }

  &.is-text-image &__wrap {
    @include media(min-tablet) {
      grid-column: 14 / 24;
    }
  }

  &.is-image-text &__text {
    @include media(max-mobile) {
     @include heading-gradient(left);
    }

    @include media(min-tablet) {
      grid-column: 14 / 24;
      @include heading-gradient(right);
    }
  }

  &.is-image-text &__wrap {
    @include media(min-tablet) {
      grid-column: 3 / 13;
    }
  }

  &__wrap {
    background: $border-gradient;
    border-radius: $border-radius-mob-md;

    @include media(max-mobile) {
      margin-bottom: $heading-gap-mob;
    }

    @include media(min-tablet) {
      border-radius: $border-radius-des-md;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;

    width: calc(100% - 4px);
    height: calc(100% - 4px);

    object-fit: cover;

    border-radius: calc($border-radius-mob-md - 1px) ;
    border: vw(375, 10) solid $color-black;

    @include media(min-tablet) {
      border: vw(1440, 10) solid $color-black;
      border-radius: calc($border-radius-des-md - 1px) ;
    }
  }

  &__subheading,
  &__heading,
  &__body {
    position: relative;
    z-index: 1;
  }

  &__heading {
    margin-bottom: vw(375, 24);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 32);
    }
  }
}
